import React, { FunctionComponent } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

interface PersonListItemProps {
  indexOf: number;
  name: string;
  image: string;
  netWorth: string;
  isSelected?: boolean;
  onClick: (name: string) => void;
  color?: string;
  Bio?: string;
}

const PersonListItem: FunctionComponent<PersonListItemProps> = ({
  name,
  image,
  netWorth,
  isSelected,
  onClick,
  color,
  indexOf,
  Bio,
}) => {
  return (
    <li
      className="flex w-auto justify-between p-2 cursor-pointer flex-column hover:bg-ctHover"
      onClick={() => onClick && onClick(name)}
    >
      <div className="flex align-middle">
        <span className="w-12 flex self-center justify-center">{indexOf}</span>
        <img
          src={image}
          alt={`${name} profile`}
          className={classnames("rounded-full w-10 h-10 object-cover", {
            [`border-3 border-${color}`]: isSelected,
          })}
        />
        <div className="pl-2 w-20">
          <h4 className="text-xs font-black leading-none uppercase text-ctBlack">
            {name}
          </h4>
          <small className="text-xs">{netWorth}</small>
        </div>
      </div>
      <Tippy content={Bio} placement="auto">
        <div className="flex self-center">
          <FontAwesomeIcon icon={faInfoCircle} />
        </div>
      </Tippy>
    </li>
  );
};

export default PersonListItem;

import React from 'react';
import ApexChart from 'react-apexcharts';
import { usePersonContext } from '../stores/PersonContext';
import getSeries from '../helpers/getSeries';

const Chart = () => {
  const { selectedPeople } = usePersonContext();
  const peopleColours = selectedPeople.map((p) => p.colour.value);
  const chartData = {
    options: {
      chart: {
        type: 'radar',
        toolbar: {
          show: false,
        },
      },
      scale: {
        min: 0,
        max: 100,
      },
      colors: peopleColours,
      fill: {
        opacity: 0,
      },
      grid: {
        strokeDashArray: 7,
        padding: {
          top: 0,
          bottom: 0,
        },
      },
      legend: {
        show: false,
      },
      markers: {
        colors: peopleColours,
        size: 3,
        hover: {
          size: undefined,
          sizeOffset: 4,
        },
      },
      plotOptions: {
        radar: {
          polygons: {
            strokeColor: '#e8e8e8',
            strokeDashArray: 7,
          },
        },
      },
      stroke: {
        colors: peopleColours,
        width: 3,
        show: true,
      },
      xaxis: {
        labels: {
          trim: true,
          style: {
            fontWeight: 700,
            fontSize: 14,
            lineHeight: 15,
            colors: ['#233354', '#233354', '#233354', '#233354', '#233354'],
            align: 'center',
          },
        },
        type: 'category',
        categories: [
          ['Wealth By Age Index'],
          ['Wealth', 'Index'],
          ['Style', 'Index'],
          ['Influence', 'Index'],
          ['Public', 'Interest', 'Index'],
        ],
      },
      yaxis: {
        show: true,
        max: 100,
        min: 0,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
          return (
            '<div class="arrow_box">' +
            '<span>' +
            (w.globals.labels[dataPointIndex].length > 1
              ? w.globals.labels[dataPointIndex].join(' ')
              : w.globals.labels[dataPointIndex]) +
            '&nbsp;&nbsp;&nbsp;&nbsp;' +
            parseInt(series[seriesIndex][dataPointIndex]) +
            '</span>' +
            '</div>'
          );
        },
      },
      responsive: [
        {
          breakpoint: 786,
          options: {
            chart: {
              width: '100%',
              height: '100%',
            },
            xaxis: {
              labels: {
                show: false,
              },
              type: 'category',
              categories: [
                ['Wealth By Age Index'],
                ['Wealth', 'Index'],
                ['Style', 'Index'],
                ['Influence', 'Index'],
                ['Public', 'Interest', 'Index'],
              ],
            },
          },
        },
      ],
    },

    series: selectedPeople.map((p) => getSeries(p)),
  };

  return (
    <div className='justify-center w-full h-full p-2 flex-grow min-h-20'>
      {chartData.series.length === 0 ? (
        <h2 className='text-xl text-center text-ctText'>
          No person selected. Click on a Person from the menu to display the
          chart.
        </h2>
      ) : (
        <ApexChart
          options={chartData.options}
          series={chartData.series}
          type='radar'
          width='100%'
          height='800px'
        />
      )}
    </div>
  );
};

export default Chart;

import Person from "../types/Person";

function getSeries(person: Person) {
  return {
    name: person.Name,
    data: [
      person["Age/Wealth Index"],
      person["Wealth Index"],
      person["Style Index"],
      person["Influence score"],
      person["Public interest score"],
    ],
  };
}

export default getSeries;

import React from "react";
import { usePersonContext } from "../stores/PersonContext";
import settings from "../config/settings";

const SelectedPeopleList = () => {
  const { selectedPeople, unselectPerson } = usePersonContext();

  return (
    <div className="flex flex-wrap lg:flex-row justify-center  w-full">
      {selectedPeople.map((person, i) => (
        <div
          className={`flex flex-row w-1/3 md:w-1/2 md:pl-0 md:justify-left lg:w-32 mx-1 mb-2 cursor-pointer md:mx-4 border-b-8 border-${person.colour.name} pb-2`}
          onClick={() => unselectPerson(person.Name)}
        >
          <img
            className={`sm:w-12 sm:h-12 md:w-16 md:h-16 w-10 h-10 border-4 rounded-full border-${person.colour.name} object-cover`}
            src={settings.imagesUrl + person.Name + ".jpg"}
            alt={person.Name}
          />
          <div className="flex flex-col w-auto md:w-0 pt-2 pl-1">
            <h2 className="text-xs font-black leading-3 break-word">
              {person.Name.split(" ").join("\r\n")}
            </h2>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SelectedPeopleList;

import React, { Fragment } from 'react';
import SelectedPeopleList from './components/SelectedPeopleList';
import Chart from './components/Chart';
import DownloadIcon from './assets/images/download.svg';
import Sidebar from './components/Sidebar';
import Modal from './components/MethodologyModal';

function App() {
  return (
    <Fragment>
      <div id='content' className='flex-col w-full md:flex md:flex-row flex  '>
        <Sidebar />
        <main className='flex flex-col items-center w-full px-4 flex-grow overflow-y-scroll'>
          <h1
            className='mt-10 text-2xl font-bold text-center uppercase font-fancy text-ctBlack'
            style={{ letterSpacing: '9.62px' }}
          >
            The Perception of Success
          </h1>
          <p
            className='mt-3 mb-6 text-center text-ctText'
            style={{ lineHeight: '22px' }}
          >
            Here at Charles Tyrwhitt, we understand success is measured in more
            than one way. In order to picture it, you need lots of different
            measurements, like those we use when designing our{' '}
            <a
              href='https://www.ctshirts.com/uk/collections.html'
              target='_blank'
            >
              men's collections
            </a>
            — that’s why we’ve created a success index We started by taking the
            top 200 people from the Sunday Times’ Rich List (for the UK) and
            Forbes’ Top 200 (for the US), then formulated five unique indexes
            against these people, to create an overall success index. In all
            circumstances, the highest index is 100 while the lowest is 0 and
            these results are scaled accordingly, you can discover more about
            this below.
          </p>

          <SelectedPeopleList />
          <Chart />
        </main>
      </div>
      <div className='flex flex-row items-center justify-between h-12 px-8 bg-ctBlack footer'>
        <a
          href={'/data.xlsx'}
          className={
            'flex flex-row items-center text-xs md:text-sm text-white uppercase '
          }
          download={true}
        >
          <img
            src={DownloadIcon}
            alt='download dataset'
            className='h-4 mr-2 text-white'
          />
          <div className='hidden md:flex'>Download complete dataset</div>
          <div className=' md:hidden'>Download </div>
        </a>

        <Modal />
      </div>
    </Fragment>
  );
}

export default App;

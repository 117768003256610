interface IIndexable {
  [key: string]: any;
}

const seriesSelectMap: IIndexable = {
  "Wealth Index": "Wealth Index",
  "Net Worth": "Net Worth £",
  "Wealth By Age Index": "Age/Wealth Index",
  "Style Index": "Style Index",
  "Influence Index": "Influence score",
  "Public Interest Index": "Public interest score",
  "Success Index": "Succes Index",
};

export default seriesSelectMap;

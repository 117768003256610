import React, { useState } from "react";
import Logo from "../assets/images/ctlogo.svg";
import data from "../data.json";
import Person from "../types/Person";
import PersonList from "./PersonList";
import classnames from "classnames";

const Sidebar = () => {
  const [isOpen, setOpen] = useState(false);
  const people: Person[] = data as any;

  return (
    <div className="flex flex-col flex-shrink-0 w-full bg-ctGrey md:w-128">
      <div className="flex flex-row  flex-shrink-0 pt-10 pb-4 px-15">
        <img src={Logo} alt="Charles Tyrwhitt Shirts" className=" pt-1" />
        <button
          onClick={() => setOpen(!isOpen)}
          className="rounded-lg md:hidden focus:outline-none focus:shadow-outline ml-4 flex"
        >
          <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
            {isOpen ? (
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            ) : (
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            )}
          </svg>
        </button>
      </div>

      <div
        className={classnames(
          "flex-grow h-full pt-8 pb-4 px-10 md:px-15 md:block md:pb-4 max-h-full overflow-y-auto",
          {
            hidden: !isOpen,
          }
        )}
      >
        <PersonList people={people} />
      </div>
    </div>
  );
};

export default Sidebar;

import React, { useContext, FunctionComponent, useState } from "react";
import data from "../data.json";
import colours from "../helpers/colors";
import sortByFilter from "../helpers/sortByFilter";
import Person, { Colour } from "../types/Person";

interface ContextProps {
  selectedPeople: Person[];
  people: Person[];
  selectPerson: (name: string) => void;
  unselectPerson: (name: string) => void;
}

const people: Person[] = data as any;

export const PersonContext = React.createContext<ContextProps>({
  people,
  selectedPeople: [],
  selectPerson: () => {},
  unselectPerson: () => {},
});

export const usePersonContext = () => useContext(PersonContext);

const PersonProvider: FunctionComponent = ({ children }) => {
  const [selectedPeople, setSelectedPeople] = useState<Person[]>(
    people
      .sort((a, b) => sortByFilter("Succes Index", a, b))
      .filter((p, i) => i < 5)
      .map((person, i) => {
        return { ...person, colour: colours[i] };
      })
  );
  const [availableColours, setAvailableColours] = useState<Colour[]>([]);

  const selectPerson = (name: string) => {
    const person: Person = people.find((p) => p.Name === name) as any;
    if (selectedPeople.length === 5) {
      setSelectedPeople([
        { ...person, colour: selectedPeople[4].colour },
        ...selectedPeople.filter((p, i) => i < 4),
      ]);
    } else {
      setSelectedPeople([
        { ...person, colour: availableColours[0] },
        ...selectedPeople,
      ]);
      setAvailableColours(availableColours.filter((c, i) => i !== 0));
    }
  };

  const unselectPerson = (name: string) => {
    const personToUnselect = selectedPeople.find((p) => p.Name === name);
    if (personToUnselect) {
      setAvailableColours((prevData) => [...prevData, personToUnselect.colour]);
      setSelectedPeople(selectedPeople.filter((p) => p.Name !== name));
    }
  };

  return (
    <PersonContext.Provider
      value={{ people, selectPerson, unselectPerson, selectedPeople }}
    >
      {children}
    </PersonContext.Provider>
  );
};

export default PersonProvider;

import React, { FunctionComponent, useState } from "react";
import PersonListItem from "./PersonListItem";
import Person from "../types/Person";
import { usePersonContext } from "../stores/PersonContext";
import TextInput from "./TextInput";
import settings from "../config/settings";
import seriesSelectMap from "../helpers/seriesSelectMap";
import sortByFilter from "../helpers/sortByFilter";

interface PersonListProps {
  people: Person[];
}

const PersonList: FunctionComponent<PersonListProps> = ({ people }) => {
  const [search, setSearch] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("Succes Index");
  const { selectPerson, unselectPerson, selectedPeople } = usePersonContext();

  const togglePerson = (name: string) => {
    const isPersonSelected = !!selectedPeople.find((sp) => sp.Name === name);

    if (isPersonSelected) {
      unselectPerson(name);
      return;
    }

    selectPerson(name);
    return;
  };

  return (
    <div className="h-full">
      <TextInput
        label="Search People:"
        placeholder="e.g Bill Gates"
        onChange={(term) => setSearch(term)}
      />
      <div className="flex flex-row justify-between w-full mt-6">
        <p className="w-20 text-sm text-ctText">Select up to 5 people</p>
        <div className="relative inline-block w-32">
          <select
            onChange={(e) => setSelectedFilter(seriesSelectMap[e.target.value])}
            className="block w-full py-3 pl-2 pr-6 text-sm leading-tight bg-white appearance-none focus:outline-none focus:shadow-outline"
          >
            <option value="Success Index">Success Index</option>

            <option value="Wealth Index">Wealth Index</option>
            <option value="Wealth By Age Index">Wealth By Age Index</option>
            <option value="Style Index">Style Index</option>
            <option value="Influence Index">Influence Index</option>
            <option value="Public Interest Index">Public Interest Index</option>
          </select>
          <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
            <svg
              className="w-4 h-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <ul className="mt-4 overflow-y-auto overflow-x-hidden people-list">
        {people
          .filter((person) =>
            person.Name.toLowerCase().includes(search.toLowerCase())
          )
          .sort((a: any, b: any) => sortByFilter(selectedFilter, a, b))
          .map((person: Person, ranking) => (
            <PersonListItem
              indexOf={ranking + 1}
              key={person.Name}
              isSelected={
                !!selectedPeople.find((sp) => sp.Name === person.Name)
              }
              name={person.Name}
              netWorth={person[selectedFilter] as string}
              image={settings.imagesUrl + person.Name + ".jpg"}
              onClick={(name: string) => togglePerson(name)}
              color={
                selectedPeople.find((p) => p.Name === person.Name)?.colour.name
              }
              Bio={"Age: " + person.Age + ". " + person.Bio}
            />
          ))}
      </ul>
    </div>
  );
};

export default PersonList;

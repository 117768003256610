import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const MethodologyModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <p
        onClick={() => setIsOpen(true)}
        className='hidden md:block text-xs cursor-pointer text-white underline'
      >
        Find out more about the methodology behind Perception of Success
      </p>
      <p
        onClick={() => setIsOpen(true)}
        className=' md:hidden text-xs cursor-pointer text-white'
      >
        Find out more
      </p>

      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <div className='p-5'>
          <h2 className='mb-5 text-center'>THE PERCEPTION OF SUCCESS</h2>
          <p
            className='mt-3 mb-6 text-center text-ctText'
            style={{ lineHeight: '22px' }}
          >
            To discover the overall most successful people, incorporating a
            variety of previously unused metrics, we started by taking the top
            200 people from the Sunday Times’ Rich List (for the UK) and Forbes’
            Top 200 (for the US). In all circumstances, the highest index is 100
            while the lowest is 0 and these results are scaled accordingly.
          </p>
          <p
            className='mt-3 mb-6 text-center text-ctText'
            style={{ lineHeight: '22px' }}
          >
            The Wealth Index was calculated by ordering in terms of net worth
            and then scaling appropriately.
            <br /> <br /> <br />
            The Wealth By Age Index is each individual’s net worth in comparison
            to their age, used to discover who has made the most per year of
            life, with 100 being the wealthiest at the lowest age.
            <br /> <br /> <br />
            The Style Index, meanwhile, was calculated by analysing survey
            results which asked respondents to rate each individual’s style from
            1-10, the highest overall average score then being scaled as 100.
            <br /> <br /> <br />
            The Influence Index is based on their social media presence,
            calculated by having a Wikipedia page alongside their total number
            of followers on Instagram, Facebook and LinkedIn.
            <br /> <br /> <br />
            The Public Influence Index was scored by calculating the total
            number of Google Searches for their name over the course of the past
            12 months.
            <br /> <br /> <br />
            Finally, the overall Success Index, which details their total
            ‘success’, was calculated by cross-referencing the previous indexes.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default MethodologyModal;

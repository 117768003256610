import Person from "../types/Person";

const sortByFilter = (filter: string, a: Person, b: Person) => {
  const aValue = a[filter];
  const bValue = b[filter];
  if (filter === "Net Worth") {
    return parseInt(aValue.replace(/,/g, "").substring(1)) <
      parseInt(bValue.replace(/,/g, "").substring(1))
      ? 1
      : -1;
  } else {
    return aValue < bValue ? 1 : -1;
  }
};

export default sortByFilter;

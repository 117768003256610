import React, { FunctionComponent } from "react";

interface TextInputProps {
  label: string;
  placeholder: string;
  onChange?: (input: string) => void;
}

const TextInput: FunctionComponent<TextInputProps> = ({
  label,
  placeholder,
  onChange,
}) => {
  return (
    <fieldset className='flex flex-col'>
      <label htmlFor='thing' className='mb-2 font-bold uppercase text-ctText'>
        {label}
      </label>
      <input
        id='thing'
        type='text'
        className='h-10 px-2 w-full'
        placeholder={placeholder}
        onChange={(e) => onChange && onChange(e.target.value)}
      />
    </fieldset>
  );
};

export default TextInput;

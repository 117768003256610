import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/styles.css"; // <- change './index.css' to './styles.css'
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import PersonProvider from "./stores/PersonContext";

ReactDOM.render(
  <PersonProvider>
    <App />
  </PersonProvider>,
  document.getElementById("root")
);
serviceWorker.unregister();
